import { zepAnalytics } from '@zep/utils/analytics';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';

export default function Footer() {
  const { t } = useTranslation();
  return (
    <footer className="flex flex-col gap-lg px-[16px] pb-4xl pt-lg min-[751px]:px-[24px] min-[1025px]:px-[40px]">
      <div className="flex items-center gap-lg min-[751px]:gap-[29px]">
        <img
          src={'/assets/logo_small_q.svg'}
          alt="ZEP QUIZ"
          className="size-[32px] min-[751px]:hidden"
        />
        <img
          src={'/assets/ZEPQuiz-logo.svg'}
          alt="ZEP QUIZ"
          className="hidden w-[146px] min-[751px]:block"
        />

        <div className="flex items-center gap-[12px]">
          <Link
            href={t('common.account.termsUrl')}
            className="flex h-[36px] items-center px-[12px] text-body-sm-semibold text-[#5D5E69]"
            onClick={() => {
              zepAnalytics.track('enter_terms', { location: 'footer' });
            }}
            target="_blank">
            {t('global.terms')}
          </Link>
          <hr className="h-[12px] w-px bg-[#B8B9C8]" />
          <Link
            href={t('common.account.privacyUrl')}
            className="flex h-[36px] items-center px-[12px] text-body-sm-semibold text-[#5D5E69]"
            onClick={() => {
              zepAnalytics.track('enter_privacy', { location: 'footer' });
            }}
            target="_blank">
            {t('global.privacy')}
          </Link>
        </div>
      </div>

      <hr className="h-px bg-[#E9EAF2]" />

      <p className="text-caption-md-medium text-assistive">
        {t('footer.value')}
      </p>
    </footer>
  );
}
