export const splitTextByUrl = (text: string) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.split(urlRegex).map(snippet => {
    if (urlRegex.test(snippet)) {
      return { type: 'url', value: snippet };
    }
    return { type: 'text', value: snippet };
  });
};

export function convertUrlsToLinks(text: string): string {
  const urlPattern: RegExp = /(https?:\/\/[^\s]+)/g;

  return text.replace(urlPattern, (url: string) => {
    return `<a href="${url}" target="_blank">${url}</a>`;
  });
}
