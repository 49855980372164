import { useTranslation } from 'next-i18next';

export const usePlanInquiryLink = () => {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation();
  return () => {
    const url = new URLSearchParams({
      webFormUrl: t('home.pricing.contactLink'),
      title:
        'ZEP QUIZ 도입이 고민이신가요?\n' +
        '선생님을 위한 최적의 솔루션을 제공해 드려요',
      desc:
        '아래 양식을 작성 후 제출해 주시면\n' +
        '담당 매니저가 빠른 시일 내로 상담을 도와드리겠습니다.',
    });

    window.open(
      resolvedLanguage === 'ko'
        ? `/embed/sales?${url}`
        : t('home.pricing.contactLink'),
    );
  };
};
